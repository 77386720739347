import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    Renderer2,
    SimpleChanges,
    ViewEncapsulation,
} from "@angular/core";
import { LooseAutocomplete } from "../pojo/Util";
import { S25LoadingInlineModule } from "../modules/s25-loading/s25.loading.inline.module";
import { NgIf } from "@angular/common";

export type ButtonClassSuffix =
    | "primary"
    | "outline"
    | "danger"
    | "danger--outline"
    | "danger--transparent"
    | "flat"
    | "success"
    | "warning";

@Component({
    selector: "s25-ng-button",
    template: `
        <button
            class="aw-button aw-button--{{ type }}"
            (click)="click.emit($event); $event?.stopImmediatePropagation()"
            [disabled]="disabled || isLoading"
            [attr.isLoading]="isLoading"
        >
            <s25-ng-loading-inline-static *ngIf="isLoading" [text]="loadingText"></s25-ng-loading-inline-static>
            <ng-container *ngIf="!isLoading">
                <ng-content></ng-content>
            </ng-container>
        </button>
    `,
    styles: `
        :host {
            display: inline-block;
        }

        button {
            height: 100%;
            width: 100%;
        }

        s25-ng-loading-inline-static {
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
        }

        ::ng-deep s25-ng-button > button.aw-button--primary > s25-ng-loading-inline-static .c-loading__inner {
            stroke: white;
        }

        ::ng-deep s25-ng-button > button > s25-ng-loading-inline-static .s25-loading {
            background-color: transparent !important;
            padding: 0;
        }
    `,
    standalone: true,
    imports: [S25LoadingInlineModule, NgIf],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
})
export class S25ButtonComponent implements OnChanges {
    @Input() type: LooseAutocomplete<ButtonClassSuffix>;
    @Input() disabled: boolean = false;
    @Input() isLoading: boolean = false;
    @Input() loadingText: string;

    @Output() click = new EventEmitter<MouseEvent>();

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isLoading) {
            if (changes.isLoading.currentValue) this.startLoading();
            else this.stopLoading();
        }
    }

    startLoading() {
        // We don't want the button's size to change when we change its contents,
        // so we need to set a fixed height and width here
        const width = this.elementRef.nativeElement.offsetWidth;
        const height = this.elementRef.nativeElement.offsetHeight;
        this.renderer.setStyle(this.elementRef.nativeElement, "width", `${width}px`);
        this.renderer.setStyle(this.elementRef.nativeElement, "height", `${height}px`);
    }

    stopLoading() {
        // In startLoading() we set a fixed height and width,
        // so we need to remove that here
        this.renderer.removeStyle(this.elementRef.nativeElement, "width");
        this.renderer.removeStyle(this.elementRef.nativeElement, "height");
    }
}
