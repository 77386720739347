import { Component, Input } from "@angular/core";
import { TypeManagerDecorator } from "../main/type.map.service";

@TypeManagerDecorator("s25-ng-bubble")
@Component({
    standalone: true,
    selector: "s25-ng-bubble",
    template: `
        <div class="line {{ position }}">
            <div class="bubbleWrapper">
                <span class="bubble">
                    <ng-content></ng-content>
                </span>
            </div>
        </div>
    `,
    styles: `
        :host {
            /* Constant */
            --notch-size: 5px;

            /* Can be changed using CSS variables from ancestors */
            --color: var(--bubble-color, black);
            --background-color: var(--bubble-background-color, white);
            --border-width: var(--bubble-border-width, 1px);
            --border-style: var(--bubble-border-style, solid);
            --border-color: var(--bubble-border-color, black);
            --box-shadow: var(--bubble-box-shadow, 0 3px 7px rgba(0, 0, 0, 0.3));

            /* Computed */
            --border: var(--border-width) var(--border-style) var(--border-color);
        }

        .line {
            width: 0;
            z-index: 2;
            height: 100%;
            color: var(--color);
            line-height: 1em;
        }

        .line .bubbleWrapper {
            position: absolute;
            width: max-content;
        }

        .line.up .bubbleWrapper {
            top: 0;
            transform: translate(-50%, calc(-100% - var(--notch-size)));
        }

        .line.down .bubbleWrapper {
            bottom: 0;
            transform: translate(-50%, calc(100% + var(--notch-size)));
        }

        .line .bubbleWrapper::before {
            content: "";
            border-left: var(--notch-size) solid transparent;
            border-right: var(--notch-size) solid transparent;
            display: block;
            position: absolute;
            left: calc(50% - var(--notch-size));
        }

        .line .bubbleWrapper::after {
            content: "";
            border-left: var(--notch-size) solid transparent;
            border-right: var(--notch-size) solid transparent;
            display: block;
            position: absolute;
            left: calc(50% - var(--notch-size));
        }

        .line.up .bubbleWrapper::before {
            border-top: var(--notch-size) var(--border-style) var(--border-color);
            bottom: calc(1px - var(--notch-size));
        }

        .line.up .bubbleWrapper::after {
            border-top: var(--notch-size) solid var(--background-color);
            bottom: calc(2px - var(--notch-size));
        }

        .line.down .bubbleWrapper::before {
            border-bottom: var(--notch-size) var(--border-style) var(--border-color);
            top: calc(1px - var(--notch-size));
        }

        .line.down .bubbleWrapper::after {
            border-bottom: var(--notch-size) var(--border-style) var(--background-color);
            top: calc(2px - var(--notch-size));
        }

        .bubble {
            background: var(--background-color);
            padding: 2px;
            border: var(--border);
            display: block;
            border-radius: 3px;
            box-shadow: var(--box-shadow);
        }
    `,
})
export class S25BubbleComponent {
    @Input({ required: true }) position: "up" | "down";
}
