import {
    ChangeDetectorRef,
    Component,
    HostListener,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Renderer2,
    SimpleChanges,
} from "@angular/core";
import { Throttle } from "../../decorators/debounce.decorator";
import { S25Util } from "../../util/s25-util";
import { Bind } from "../../decorators/bind.decorator";
import { S25ScrollUtil } from "./s25.scroll.util";
import { NgIf } from "@angular/common";

@Component({
    selector: "s25-ng-scroll-minimap",
    template: `
        <div
            *ngIf="windowWidth !== width || windowHeight !== height"
            class="minimap"
            [style.width.px]="width"
            [style.height.px]="height"
        >
            <div
                class="window"
                [style.width.px]="windowWidth"
                [style.height.px]="windowHeight"
                [style.top.px]="windowTop"
                [style.left.px]="windowLeft"
            ></div>
        </div>
    `,
    styles: `
        :host {
            --background: var(--minimap-background, #d3d3d3);
            --border: var(--minimap-border, 1px solid black);
            --opacity: var(--minimap-opacity, 0.7);
            --window-background: var(--minimap-window-background, transparent);
            --window-border: var(--minimap-window-border, 1px solid black);

            z-index: 10;
        }

        .minimap {
            background: var(--background);
            border: var(--border);
            opacity: var(--opacity);
            cursor: pointer;
            user-select: none;
        }

        .window {
            pointer-events: none;
            background: var(--window-background);
            border: var(--window-border);
            position: absolute;
            background: rgba(255, 255, 255, 0.3);
        }
    `,
    standalone: true,
    imports: [NgIf],
})
export class S25ScrollMinimapComponent implements OnChanges, OnDestroy {
    // Typically these three elements may be the same, but here they can be specified separately for more control
    @Input() scrollElementX: HTMLElement; // The element that will be scrolled horizontally
    @Input() scrollElementY: HTMLElement; // The element that will be scrolled vertically
    @Input() windowElement: HTMLElement; // The element which represents the "window" in the minimap
    @Input() size: number = 250; // px, depending on aspect ratio of element this can be either width or height
    @Input() minSize: number = 25; // px, minimum for both width & height

    dragOrigin: { clientX: number; clientY: number; offsetX: number; offsetY: number };

    width: number; // px
    height: number; // px

    limit: {
        top: { min: number; max: number };
        left: { min: number; max: number };
    };

    windowWidth: number; // px
    windowHeight: number; // px
    windowTop: number = 0; // px
    windowLeft: number = 0; // px

    scaleX: number;
    scaleY: number;

    unlistenMousemove: () => void;
    resizeObserver: ResizeObserver;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private zone: NgZone,
        private renderer: Renderer2,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        this.removeEventListeners.apply({
            ...this,
            scrollElementX: changes.scrollElementX?.previousValue,
            scrollElementY: changes.scrollElementY?.previousValue,
        });
        this.setEventListeners();
        this.updateMinimapSize();
    }

    ngOnDestroy() {
        this.removeEventListeners();
    }

    removeEventListeners() {
        this.unlistenMousemove?.();
        document.removeEventListener("scroll", this.onScroll, true);
        for (let child of this.scrollElementX?.children || []) this.resizeObserver?.unobserve(child);
        for (let child of this.scrollElementY?.children || []) this.resizeObserver?.unobserve(child);
        this.resizeObserver = null;
    }

    setEventListeners() {
        if (!this.windowElement || !this.scrollElementX || !this.scrollElementY) return;

        // This is not "the Angular way", but a HostListener cannot listen to these events since scroll doesn't bubble and
        // HostListeners cannot capture events
        this.zone.runOutsideAngular(() => {
            document.addEventListener("scroll", this.onScroll, true); // This has to be a normal event listener because renderer.listen doesn't seem to catch bubbled events
            this.unlistenMousemove = this.renderer.listen(document, "mousemove", this.onMousemove);
        });
        this.resizeObserver = new ResizeObserver(this.updateMinimapSize);
        for (let child of this.scrollElementX.children) this.resizeObserver.observe(child);
        for (let child of this.scrollElementY.children) this.resizeObserver.observe(child);
    }

    @HostListener("window:resize")
    @Throttle(100)
    // Calculate the size of the minimap based on the actual elements
    updateMinimapSize() {
        if (!this.windowElement || !this.scrollElementX || !this.scrollElementY) return;

        // Find the scroll limits
        const { limit, offsetBottom, offsetRight, offsetTop, offsetLeft } = S25ScrollUtil.getScrollLimit(
            this.scrollElementX,
            this.scrollElementY,
            this.windowElement,
        );
        this.limit = limit;

        // Calculate the width and height of the area we are interested in
        const width = this.scrollElementX.scrollWidth - offsetLeft - offsetRight;
        const height = this.scrollElementY.scrollHeight - offsetTop - offsetBottom;
        const aspectRatio = width / height;

        // Calculate the width and height of our minimap
        if (aspectRatio > 1) {
            this.width = this.size;
            this.height = Math.max(Math.round(this.size / aspectRatio), this.minSize);
        } else {
            this.width = Math.max(Math.round(this.size * aspectRatio), this.minSize);
            this.height = this.size;
        }

        // Calculate the scale of the minimap
        this.scaleX = width / this.width;
        this.scaleY = height / this.height;

        // Calculate the size of our "window" element
        this.windowWidth = Math.min(
            Math.round((this.width * Math.min(this.scrollElementX.clientWidth, window.innerWidth)) / width),
            this.width,
        );
        this.windowHeight = Math.min(
            Math.round((this.height * Math.min(this.scrollElementY.clientHeight, window.innerHeight)) / height),
            this.height,
        );

        this.changeDetector.detectChanges();
    }

    @HostListener("mousedown", ["$event"])
    onMousedown(event: MouseEvent) {
        const { clientX, clientY, offsetX, offsetY } = event;
        this.dragOrigin = { clientX, clientY, offsetX, offsetY };
    }

    // Drag the window and scroll the elements
    @Bind
    onMousemove(event: MouseEvent) {
        this.moveWindow(event);
    }

    @HostListener("document:mouseup", ["$event"]) // Listen to mouseup everywhere
    // Cancel dragging the "window"
    onMouseup(event: MouseEvent) {
        this.moveWindow(event);
        this.dragOrigin = null;
    }

    moveWindow(event: MouseEvent) {
        if (!this.dragOrigin) return;

        const offsetY = this.dragOrigin.offsetY + event.clientY - this.dragOrigin.clientY;
        const offsetX = this.dragOrigin.offsetX + event.clientX - this.dragOrigin.clientX;

        // Shift offset by half window height so that the cursor is in the middle
        // and clamp so that it doesn't go outside the minimap
        this.windowTop = S25Util.clamp(offsetY - this.windowHeight / 2, 0, this.height - this.windowHeight);
        // Multiply the window offset by the scale multiplier to get the real offset, and add to the scroll baseline
        const scrollTop = this.windowTop * this.scaleY + this.limit.top.min;
        this.scrollElementY.scrollTo({
            top: S25Util.clamp(scrollTop, this.limit.top.min, this.limit.top.max),
            behavior: "instant",
        });

        // Shift offset by half window width so that the cursor is in the middle
        // and clamp so that it doesn't go outside the minimap
        this.windowLeft = S25Util.clamp(offsetX - this.windowWidth / 2, 0, this.width - this.windowWidth);
        // Multiply the window offset by the scale multiplier to get the real offset, and add to the scroll baseline
        const scrollLeft = this.windowLeft * this.scaleX + this.limit.left.min;
        this.scrollElementX.scrollTo({
            left: S25Util.clamp(scrollLeft, this.limit.left.min, this.limit.left.max),
            behavior: "instant",
        });

        this.changeDetector.detectChanges();
    }

    // Reposition the "window" when we scroll
    @Bind
    onScroll() {
        if (!this.windowElement || !this.scrollElementX || !this.scrollElementY || this.dragOrigin) return;

        const offsetY = this.scrollElementY.scrollTop;
        const offsetX = this.scrollElementX.scrollLeft;

        this.windowTop = S25Util.clamp(
            (offsetY - this.limit.top.min) / this.scaleY,
            0,
            this.height - this.windowHeight,
        );
        this.windowLeft = S25Util.clamp(
            (offsetX - this.limit.left.min) / this.scaleX,
            0,
            this.width - this.windowWidth,
        );
        this.changeDetector.detectChanges();
    }
}
