import { ResourceSpaceAvailService } from "../services/resource.space.avail.service";
import { jSith } from "./jquery-replacement";
import { S25Util } from "./s25-util";

export class ObjectSearchAvailabilityUtil {
    public static checkAvailability(
        contextEventId: number,
        contextProfileId: number,
        reservations: any,
        accumItems: any,
        perItemF: any,
        specificOccs?: any,
        inclQuotas?: boolean,
    ) {
        return ResourceSpaceAvailService.checkAvailability(contextEventId, contextProfileId, reservations).then(
            function () {
                jSith.forEach(accumItems, function (_, item) {
                    if (specificOccs) {
                        jSith.forEach(specificOccs, function (_, occ) {
                            var someReal = false;
                            var len = item.checkedObj.conflicts.length;
                            for (var i = len - 1; i >= 0; i--) {
                                var conflict = item.checkedObj.conflicts[i];
                                if (conflict.occUUID === occ.uuid) {
                                    someReal = someReal || conflict.isReal;
                                    item.checkedObj.conflicts.splice(i, 1);
                                }
                            }

                            if (len > item.checkedObj.conflicts.length) {
                                if (someReal) {
                                    item.checkedObj.availableDatesWithOverrides += 1;
                                }
                                item.checkedObj.availableDates += 1;
                                item.checkedObj.conflictingDates -= 1;
                            }

                            item.checkedObj.hasConflicts = item.checkedObj.conflicts.length > 0;
                            item.checkedObj.hasRealConflicts =
                                item.checkedObj.conflicts.filter(function (conflict: any) {
                                    return conflict.isReal;
                                }).length > 0;
                        });
                    } else {
                        item.checkedObj = {
                            conflicts: [],
                            hasConflicts: false,
                            hasRealConflicts: false,
                            totalDatesChecked: reservations.length,
                            availableDates: reservations.length,
                            conflictingDates: 0,
                            availableDatesWithOverrides: reservations.length,
                        };

                        item.apRestriction = undefined;
                    }

                    jSith.forEach(reservations, function (_, rsrv) {
                        item.itemTypeId === 4 &&
                            jSith.forEach(rsrv.space_reservation, function (_, spRsrv) {
                                //ANG-4669 flag any low AP reservations
                                if (
                                    item.itemId === parseInt(spRsrv.space_id) &&
                                    spRsrv.apNoRequest &&
                                    S25Util.date.equal(
                                        S25Util.date.dropTZString(spRsrv.apViolationDate),
                                        S25Util.date.dropTZString(rsrv.reservation_start_dt),
                                    )
                                ) {
                                    item.apRestriction = {
                                        startDt: spRsrv.apViolationDate,
                                        apNoRequest: true,
                                        reason: spRsrv.apReason,
                                    };
                                }

                                if (
                                    item.itemId === parseInt(spRsrv.space_id) &&
                                    spRsrv.conflicts &&
                                    spRsrv.conflicts.length
                                ) {
                                    item.checkedObj.availableDatesWithOverrides -= spRsrv.hasRealConflicts ? 1 : 0;
                                    item.checkedObj.availableDates -= 1;
                                    item.checkedObj.conflictingDates += 1;
                                    item.checkedObj.conflicts = item.checkedObj.conflicts.concat(spRsrv.conflicts);
                                    item.checkedObj.hasConflicts = item.checkedObj.hasConflicts || spRsrv.hasConflicts;
                                    item.checkedObj.hasRealConflicts =
                                        item.checkedObj.hasRealConflicts || spRsrv.hasRealConflicts;
                                }
                            });

                        item.itemTypeId === 6 &&
                            jSith.forEach(rsrv.resource_reservation, function (_, rsRsrv) {
                                //ANG-4669 flag any low AP reservations
                                if (
                                    item.itemId === parseInt(rsRsrv.resource_id) &&
                                    rsRsrv.apNoRequest &&
                                    S25Util.date.equal(
                                        S25Util.date.dropTZString(rsRsrv.apViolationDate),
                                        S25Util.date.dropTZString(rsrv.reservation_start_dt),
                                    )
                                ) {
                                    item.apRestriction = {
                                        startDt: rsRsrv.apViolationDate,
                                        apNoRequest: true,
                                        reason: rsRsrv.apReason,
                                    };
                                }

                                if (
                                    item.itemId === parseInt(rsRsrv.resource_id) &&
                                    rsRsrv.conflicts &&
                                    rsRsrv.conflicts.length
                                ) {
                                    item.checkedObj.availableDatesWithOverrides -= rsRsrv.hasRealConflicts ? 1 : 0;
                                    item.checkedObj.availableDates -= 1;
                                    item.checkedObj.conflictingDates += 1;
                                    item.checkedObj.conflicts = item.checkedObj.conflicts.concat(rsRsrv.conflicts);
                                    item.checkedObj.hasConflicts = item.checkedObj.hasConflicts || rsRsrv.hasConflicts;
                                    item.checkedObj.hasRealConflicts =
                                        item.checkedObj.hasRealConflicts || rsRsrv.hasRealConflicts;
                                }
                            });
                    });

                    if (item.itemTypeId === 6) {
                        item.allResAvailDateMap = item.allResAvailDateMap || {};
                        S25Util.extend(item.allResAvailDateMap, reservations.allResAvailDateMap);

                        var unlimited = Math.pow(2, 31);
                        var minAvailable = unlimited,
                            maxAvailable = -1,
                            stockTotal = item.stock_level || -1;

                        jSith.forEach(reservations, function (_, rsrv) {
                            var available;
                            var key =
                                item.itemId +
                                "&" +
                                S25Util.date.toS25ISODateTimeStr(S25Util.date.parseDropTZ(rsrv.reservation_start_dt));
                            var resAvailDateObj = item.allResAvailDateMap[key];
                            if (resAvailDateObj) {
                                available = resAvailDateObj.stockLevel;
                                stockTotal = resAvailDateObj.stockTotal;
                            }

                            available = Math.max(S25Util.coalesce(available, unlimited), 0);

                            if (available > maxAvailable) {
                                maxAvailable = available;
                            }

                            if (available < minAvailable) {
                                minAvailable = available;
                            }

                            if (available > stockTotal) {
                                stockTotal = available;
                            }
                        });

                        item.quantity = stockTotal > -1 && stockTotal < unlimited ? stockTotal : "Unlimited";
                        item.minAvailable = minAvailable;
                        item.maxAvailable = maxAvailable;
                        item.availability = "";

                        if (item.maxAvailable === 0) {
                            //if no resource total available, then available dates should be 0, causing "Unavailable" to show for action
                            item.checkedObj.availableDatesWithOverrides = 0;
                            item.checkedObj.availableDates = 0;
                        }

                        if (item.quantity === "Unlimited") {
                            item.availability = "Unlimited";
                        } else {
                            if (item.minAvailable === item.maxAvailable) {
                                item.availability = item.minAvailable + " / " + item.quantity;
                            } else {
                                item.availability =
                                    item.minAvailable + " to " + item.maxAvailable + " / " + item.quantity;
                            }
                        }
                    }

                    item.checkedObj.conflicts.sort(S25Util.shallowSortDates("candidateDate", "conflictStart"));

                    perItemF && perItemF(item);
                });

                return reservations;
            },
        );
    }
}
