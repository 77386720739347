import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { TypeManagerDecorator } from "../main/type.map.service";
import { S25Util } from "../util/s25-util";
import { S25BubbleComponent } from "./s25.bubble.component";

@TypeManagerDecorator("s25-ng-time-bubble")
@Component({
    standalone: true,
    selector: "s25-ng-time-bubble",
    template: ` <s25-ng-bubble [position]="position">{{ time }}</s25-ng-bubble> `,
    styles: `
        :host {
            --bubble-color: var(--time-bubble-color, var(--avail-text-color, black));
        }
    `,
    imports: [S25BubbleComponent],
})
export class S25TimeBubbleComponent implements OnChanges {
    @Input() position: "up" | "down";
    @Input() time: string;
    @Input() hours: number; // Hour of day, float
    @Input() is24Hours: boolean;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hours || changes.is24Hours) this.onHoursChange();
    }

    onHoursChange() {
        if (typeof this.hours !== "number") return;
        this.time = S25Util.date.toTimeStrFromHours(this.hours, this.is24Hours);
    }
}
