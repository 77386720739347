import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../util/s25-util";
import { TypeManagerDecorator } from "../main/type.map.service";
import { CommonModule, NgIf } from "@angular/common";

export const enum ReportFormat {
    PDF = 2,
    Excel = 4,
    RichText = 6,
    HTML = 1,
    Text = 3,
}

@TypeManagerDecorator("s25-ng-report-file-type")
@Component({
    standalone: true,
    imports: [CommonModule, NgIf],
    selector: "s25-ng-report-file-type",
    template: `
        <div *ngIf="init" class="c-margin-left--double">
            <label class="c-margin-bottom--half">
                <label [for]="id">Report Format:</label>
                <select
                    [id]="id"
                    class="cn-form__control c-margin-top--quarter c-margin-left--half"
                    (change)="changeType($event)"
                >
                    <option *ngFor="let f of reportFormatList" [value]="f.itemId">
                        {{ f.itemName }}
                    </option>
                </select>
            </label>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ReportFileTypeComponent implements OnInit {
    @Input() formatType?: ReportFormat.PDF;
    @Input() reportId?: number;
    @Input() onChange: (obj: any) => any = undefined;
    @Output() modelValueChange = new EventEmitter();

    static count: number = 0;
    id: string;
    init: boolean = false;
    reportFormatList = [
        { itemId: 2, itemName: "PDF" },
        { itemId: 4, itemName: "Excel" },
        { itemId: 6, itemName: "Rich Text" },
        { itemId: 1, itemName: "HTML" },
        { itemId: 3, itemName: "Text" },
    ];

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        this.id = "ngFileType-" + S25ReportFileTypeComponent.count++;
    }

    ngOnInit() {
        this.init = true;
        this.cd.detectChanges();
    }

    changeType(e: any) {
        this.formatType = parseInt(e.target.value);
        let data = { formatType: this.formatType, reportId: this.reportId };
        this.onChange && S25Util.isFunction(this.onChange) && this.onChange(data); // hook into JS
        this.modelValueChange.emit(data);
    }
}
